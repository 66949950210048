import React, { Suspense } from 'react';
import {
  Grid, Paper, useMediaQuery, useTheme,
} from '@mui/material';

import PageHelmet from 'components/layout/PageHelmet';
import { Loading } from 'components/Loading';
import useMapsAPI from 'components/map/utils/useMapsAPI';

import { makeStyles } from '@mui/styles';
// @ts-ignore
import bg from '../assets/bg-buildings.png';
// @ts-ignore
import logo from '../assets/logo-white.png';

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    height: '100vh',
  },
  logoContainer: {
    backgroundImage: `url(${bg})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 160,
    [theme.breakpoints.up('sm')]: {
      height: 200,
    },
    [theme.breakpoints.up('md')]: {
      height: 'auto',
    },
  },
  logo: {
    width: 160,
    [theme.breakpoints.up('sm')]: {
      width: 200,
    },
    [theme.breakpoints.up('md')]: {
      width: 160,
    },
    [theme.breakpoints.up('lg')]: {
      width: 200,
    },
  },
  formContainer: {
    padding: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 0),
    },
  },
  formPaper: {
    width: '100%',
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      width: '80%',
      padding: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
      padding: theme.spacing(6),
    },
    [theme.breakpoints.up('lg')]: {
      width: '60%',
      padding: theme.spacing(6),
    },
    [theme.breakpoints.up('xl')]: {
      width: '50%',
      padding: theme.spacing(6),
    },
  },
}));

export interface AuthPageProps {
  title?: string;
  children?: React.ReactNode;
}

export const AuthPage: React.FC<AuthPageProps> = ({ title, children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { mapsApiLoaded } = useMapsAPI();

  if (!mapsApiLoaded) {
    return <Loading center />;
  }

  return (
    <Grid
      container
      direction={upMd ? 'row' : 'column'}
      className={upMd ? classes.fullHeight : ''}
    >
      <PageHelmet title={title} />
      <Grid
        item
        md={4}
        className={classes.logoContainer}
        container
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <img
          src={logo}
          alt="Procursys logo"
          className={classes.logo}
        />
      </Grid>
      <Grid
        item
        md={8}
        className={classes.formContainer}
        container
        justifyContent="center"
        alignContent="center"
        alignItems="center"
      >
        <Paper
          variant={upSm ? 'outlined' : 'elevation'}
          square={!upSm}
          elevation={0}
          className={classes.formPaper}
        >
          <Suspense fallback={<Loading center />}>
            {children}
          </Suspense>
        </Paper>
      </Grid>
    </Grid>
  );
};
