import React, { Suspense } from 'react';
import {
  Container, Paper, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import PageHelmet from 'components/layout/PageHelmet';
import { AnonymousAppBar } from 'components/nav/AnonymousAppBar';
import { Loading } from 'components/Loading';
import useMapsAPI from 'components/map/utils/useMapsAPI';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
  },
  title: {
    padding: theme.spacing(2, 4, 0, 4),
    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
  },
  subtitle: {
    padding: theme.spacing(0, 4),
    [theme.breakpoints.up('sm')]: {
      padding: 0,
    },
  },
  paper: {
    padding: theme.spacing(4),
  },
}));

export interface BlackPageProps {
  title: string;
  noindex?: boolean;
  subtitle: JSX.Element | string;
  headerContent?: JSX.Element | null;
  children?: React.ReactNode;
}

export const BlankPage: React.FC<BlackPageProps> = ({
  title,
  noindex,
  subtitle,
  headerContent,
  children,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { mapsApiLoaded } = useMapsAPI();

  if (!mapsApiLoaded) {
    return <Loading center fullScreen />;
  }

  return (
    <>
      <PageHelmet title={title} noindex={noindex} />
      <AnonymousAppBar rightContent={headerContent} />
      <Container maxWidth="md" className={classes.container}>
        <Typography variant="h5" paragraph className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" paragraph className={classes.subtitle}>
          {subtitle}
        </Typography>
        <Paper
          variant={upSm ? 'outlined' : 'elevation'}
          square={!upSm}
          elevation={0}
          className={classes.paper}
        >
          <Suspense fallback={<Loading center />}>
            {children}
          </Suspense>
        </Paper>
      </Container>
    </>
  );
};
