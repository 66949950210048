import React, {
  createContext, useCallback, useMemo, useContext, useState, Dispatch, useEffect,
} from 'react';
import { LocationState, History } from 'history';

export const LOGIN_AS_SEARCH_PARAM = 'loginAs';

interface LoginAsContextValue {
  loginAs: number | null;
  handleLoginAs: (organization_id: number, path: string, state?: LocationState) => void;
  removeLoginAs: () => void;
}

const LoginAsContext = createContext<LoginAsContextValue | null>(null);

export const useLoginAs = (): LoginAsContextValue => {
  const value = useContext(LoginAsContext);
  if (!value) {
    throw new Error('LoginAsContext was not initialized');
  }
  return value;
};

export const getLoginAsFromSession = (): number | null => {
  const sessionRawLoginAs = window.sessionStorage.getItem(LOGIN_AS_SEARCH_PARAM);
  return sessionRawLoginAs ? parseInt(sessionRawLoginAs, 10) : null;
};

function useLoginAsState<S extends number | null = number | null>(
  search?: string,
): [S, Dispatch<S>] {
  const [loginAs, setLocalLoginAs] = useState<S>(() => {
    // get loginAs param from url
    const searchParams = new URLSearchParams(search);
    const urlRawLoginAs = searchParams.get(LOGIN_AS_SEARCH_PARAM);
    const urlLoginAs = urlRawLoginAs ? parseInt(urlRawLoginAs, 10) : null;
    // get loginAs param from session
    const sessionLoginAs = getLoginAsFromSession();
    return (urlLoginAs ?? sessionLoginAs) as S;
  });

  useEffect(() => {
    if (loginAs) {
      window.sessionStorage.setItem(LOGIN_AS_SEARCH_PARAM, loginAs.toString());
    } else {
      window.sessionStorage.removeItem(LOGIN_AS_SEARCH_PARAM);
    }
  }, [loginAs]);

  const setLoginAs = useCallback((v: S) => {
    setLocalLoginAs(v);
  }, [setLocalLoginAs]);

  return [
    loginAs,
    setLoginAs,
  ];
}

const LoginAsProvider: React.FC<{
  history: History,
  children?: React.ReactNode,
}> = ({ history, children }) => {
  const [loginAs, setLoginAs] = useLoginAsState(history.location.search);

  const handleLoginAs = useCallback((
    organization_id: number,
    path: string,
    state?: LocationState,
  ) => {
    setLoginAs(organization_id);
    history.push(path, state);
  }, [setLoginAs, history]);

  const removeLoginAs = useCallback(() => setLoginAs(null), [setLoginAs]);

  const value = useMemo(() => ({
    loginAs,
    handleLoginAs,
    removeLoginAs,
  }), [loginAs, handleLoginAs, removeLoginAs]);

  return (
    <LoginAsContext.Provider value={value}>
      {children}
    </LoginAsContext.Provider>
  );
};

export const hocLoginAs = (history: History, Component: React.FC<any>): React.FC => () => (
  <LoginAsProvider history={history}>
    <Component />
  </LoginAsProvider>
);
