import { DefaultGenerics, UR } from 'stream-chat';
import { DefaultChannelType } from 'stream-chat-react/dist/types/types';

/**
 * Overrides for custom data in channel
 */
export interface ChannelType extends DefaultChannelType {
  fm_name: string;
  fm_id: number;
  sp_name: string;
  sp_id: number;
  has_real_messages: boolean;
  archived_by_sp?: boolean;
  archived_by_fm?: boolean;
}

export enum MessageSentFrom {
  Web = 'web',
  Phone = 'phone',
  App = 'app', // mobile app
}

export interface MessageType extends UR {
  is_notification?: boolean;
  from_organization_id?: number;
  sent_from?: MessageSentFrom,
}

export interface UserType extends UR {
  organization_id: number;
  image?: string;
}

export enum AttachmentRole {
  BundlePreview = 'bundle_preview',
}

export interface AttachmentType extends UR {
  proc_type?: AttachmentRole;
}

export type ChatGenerics = DefaultGenerics & {
  attachmentType: AttachmentType;
  channelType: ChannelType;
  messageType: MessageType;
  userType: UserType;
};
