import React, { useMemo } from 'react';
import {
  Channel,
  MessageInput as DefaultMessageInput,
  Window,
} from 'stream-chat-react';
import './ChatSidebar.scss';
import { ChannelList } from '../ChannelList/ChannelList';
import { ChatProvider } from '../ChatContext';
import { ThreadHeader } from '../Thread/ThreadHeader';
import { MessageInput } from '../MessageInput/MessageInput';
import { MessageList } from '../MessageList/MessageList';

import '../Chat.scss';

export interface ChatSidebarProps {
  channelId: string;
  defaultTopic?: string;
}

export const ChatSidebar: React.FC<ChatSidebarProps> = ({
  channelId,
  defaultTopic,
}) => (
  <ChatProvider
    channelId={channelId}
    additionalProps={useMemo(() => (defaultTopic ? {
      defaultMessageValue: `RE: ${defaultTopic} - `,
    } : {}), [defaultTopic])}
  >
    <div style={{ display: 'none' }}>
      <ChannelList customActiveChannel={channelId} />
    </div>
    <div className="str-chat-sidebar">
      <Channel Input={MessageInput} ThreadHeader={ThreadHeader}>
        <Window>
          <MessageList />
          <DefaultMessageInput />
        </Window>
      </Channel>
    </div>
  </ChatProvider>
);
