import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Drawer, IconButton, Typography } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import cn from 'classnames';

interface StyleProps {
  unrestrictedWidth?: boolean;
  disableTitleBorderBottom?: boolean;
  width?: number;
}

export interface SidebarProps extends StyleProps {
  open: boolean;
  onClose?: () => void;

  // Content of sidebar
  title: string | JSX.Element;
  disableTitleBorderBottom?: boolean;
  content: JSX.Element | null;
  children?: React.ReactNode;
}

export const drawerSidebarWidth = 500;
// NOTE: we consider, that there is no devices with width less that 200px
export const drawerSidebarMinWidth = 200;
export const drawerHeaderMinHeight = 64;

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    flexShrink: 0,
    // Full screen map overlay has z-index: 1209 (/src/index.scss), so we want to overlap it
    zIndex: 1210,
  },
  drawerOpen: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minWidth: drawerSidebarMinWidth,
    },
    [theme.breakpoints.up('sm')]: {
      width: (props: StyleProps) => props.width ?? drawerSidebarWidth,
    },
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClosed: {
    width: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaper: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      minWidth: drawerSidebarMinWidth,
    },
    [theme.breakpoints.up('sm')]: {
      width: (props: StyleProps) => props.width ?? drawerSidebarWidth,
    },
    boxShadow: theme.shadows[8],
  },
  drawerHeaderPadInsertion: {
    ...theme.mixins.toolbar,
  },
  drawerHeader: (props: StyleProps) => ({
    display: 'flex',
    position: 'absolute',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    zIndex: theme.zIndex.appBar,
    background: 'white',
    borderBottom: props.disableTitleBorderBottom ? 'none' : `1px solid ${theme.palette.divider}`,
    minHeight: drawerHeaderMinHeight,
  }),
  content: {
    width: (props: StyleProps) => (props.unrestrictedWidth ? undefined : '100%'),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
  },
}));

export const Sidebar: React.FC<SidebarProps> = ({
  open,
  onClose,
  title,
  disableTitleBorderBottom,
  content,
  unrestrictedWidth,
  width,
  children,
}) => {
  const classes = useStyles({ width, unrestrictedWidth, disableTitleBorderBottom });

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {children}
      </div>
      <Drawer
        className={cn(
          classes.drawer,
          open ? classes.drawerOpen : classes.drawerClosed,
        )}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <Typography variant="h6">
            {title}
          </Typography>
          {onClose && (
            <IconButton onClick={onClose} size="large">
              <Cancel />
            </IconButton>
          )}
        </div>
        <div className={classes.drawerHeaderPadInsertion} />
        {content}
      </Drawer>
    </div>
  );
};
