import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'isomorphic-unfetch';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import 'react-activity-feed/dist/index.css';
import 'stream-chat-react/dist/scss/v2/index.scss';
import * as Sentry from '@sentry/browser';
import firebase from 'firebase/app';
import TagManager from 'react-gtm-module';
import { ErrorPageFallback } from 'components/notifications/ErrorPageFallback';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { config } from './config';

import 'firebase/auth';
import 'firebase/analytics';
import 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw-src.css';
import 'leaflet-measure';
import 'leaflet-measure/dist/leaflet-measure.css';
import 'leaflet-fullscreen';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import './components/map/MarketCluster.Branded.css';
import 'leaflet.markercluster/dist/leaflet.markercluster';
import 'utils/yupExtensions';
import 'react-virtualized/styles.css';

// Start Sentry first, there may be some errors in App or firebase initialization
Sentry.init(config.sentry);

firebase.initializeApp(config.firebase);
firebase.analytics();

if (config.gtm) {
  TagManager.initialize({
    gtmId: config.gtm.id,
    auth: config.gtm.auth,
    preview: config.gtm.preview,
  });
}

const errorHandler = (error: Error /* , info: { componentStack: string } */) => {
  Sentry.captureException(error);
};

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <ErrorBoundary FallbackComponent={ErrorPageFallback} onError={errorHandler}>
    <App />
  </ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
