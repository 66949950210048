import React, { useCallback, useMemo, useState } from 'react';
import {
  Box, LinearProgress, Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Form, FormikContext, FormikProps } from 'formik';
import { ApolloError } from '@apollo/client';
import { FilesDropZone } from './FilesDropZone';
import { UploadFilesList } from './uploadFilesList/UploadFilesList';
import { FileUploadContext, WhereOrganizationFileInput } from '../../api/types/globalTypes';
import { FilesList, FilesListProps } from './filesList/FilesList';
import { Files__organizationFiles_items } from '../../api/types/Files';
import { DocumentRepositoryForm, DocumentRepositoryMode } from './useDocumentRepository';
import { ErrorSnackbar, useErrorSnackbar } from '../notifications/ErrorSnackbar';
import { TooltipButton } from '../buttons/TooltipButton';
import { colors } from '../theme';
import { useProfile } from '../../api/user/profile';
import { FileCategories } from './queries/categories';
import { useHookSubPageControls } from '../organization/sp/onboarding/useHookSubPageControls';
import { FileBadge } from './filesList/FilesListItem';

export interface DocumentRepositoryProps {
  integrationMode?: 'standalone' | 'integrated';
  mode: DocumentRepositoryMode;
  form: FormikProps<DocumentRepositoryForm>;
  allowToAddDocusignTemplates?: boolean;
  uploadError?: ApolloError | Error | null;
  hasRejectedFiles: boolean;
  splitCategories?: FileCategories[];
  showContext?: FileUploadContext,
  showRepositoryFiles?: boolean;
  allowSetPublic?: boolean;
  allowAddToRepository?: boolean;
  categoriesFor?: 'repository' | 'eContract';
}

export const DocumentRepository: React.FC<DocumentRepositoryProps> = (({
  integrationMode,
  mode,
  form,
  uploadError,
  hasRejectedFiles,
  showRepositoryFiles = true,
  allowToAddDocusignTemplates,
  allowSetPublic = true,
  allowAddToRepository = true,
  categoriesFor,
  showContext,
}) => {
  // eslint-disable-next-line no-console
  const { organization } = useProfile();
  const {
    values, setFieldValue, isValid, isSubmitting,
  } = form;

  const uploadErrorSnackbar = useErrorSnackbar(uploadError);

  const handleFileSelection = useCallback((
    file: Files__organizationFiles_items, checked: boolean,
  ) => {
    setFieldValue('selectedFiles', checked
      ? [...values.selectedFiles, file]
      : values.selectedFiles.filter((f) => f.file_id !== file.file_id),
    );
  }, [setFieldValue, values.selectedFiles]);

  const selectedIds = useMemo(() => values.selectedFiles.reduce(
    (acc, item) => acc.add(item.file_id), new Set<number>()),
  [values.selectedFiles]);

  const repositoryFilesQuery: WhereOrganizationFileInput | undefined = useMemo(() => ({
    organization_id: { eq: organization.organization_id },
    ...(showContext ? {
      ...(showContext.service_id ? ({ service_id: { eq: showContext.service_id } }) : {}),
      ...(showContext.bid_id ? ({ bid_id: { eq: showContext.bid_id } }) : {}),
      ...(showContext.contract_term_id ? ({
        contract_term_id: { eq: showContext.contract_term_id },
      }) : {}),
      ...(showContext.site_id ? ({ site_id: { eq: showContext.site_id } }) : {}),
      ...(showContext.envelope_id ? ({ envelope_id: { eq: showContext.envelope_id } }) : {}),
    } : {
      show_in_repository: { eq: true },
    }),
    ...(!allowToAddDocusignTemplates ? { type: { not: 'docusign_template' } } : {}),
  }), [organization, showContext, allowToAddDocusignTemplates]);

  const showFileBadges: FilesListProps['getFileBadges'] = useCallback((file) => {
    const badges: FileBadge[] = [];
    if (file.is_public) {
      badges.push({
        backgroundColor: colors.orange,
        title: 'Profile Sidebar',
      });
    }
    return badges;
  }, []);

  const isSelectMode = mode === 'selection';
  const isStandalone = mode === 'standalone';
  const canSubmit = !hasRejectedFiles && isValid && values.files.length && !isSubmitting;

  const [files, setFiles] = useState<Files__organizationFiles_items[]>([]);
  const getFiles = useCallback((f: Files__organizationFiles_items[]) => {
    setFiles(f);
  }, []);

  const getState = useCallback(() => [
    {
      sectionName: 'Documents',
      steps: [
        {
          name: 'W9',
          description: 'Upload W9 document',
          progress: files
            .filter((f) => f.category.category_id === FileCategories.W9)
            .length ? 1 : 0,
        },
        {
          name: 'COI',
          description: 'Upload Certificate Of Insurance',
          progress: files
            .filter((f) => (
              f.category.category_id === FileCategories.CertificateOfInsurance
            ))
            .length ? 1 : 0,
        },
      ],
    },
  ], [files]);
  useHookSubPageControls(integrationMode ?? 'standalone', undefined, getState);

  return (
    <FormikContext.Provider value={form}>
      <Form>
        <FilesDropZone allowToAddDocusignTemplates={allowToAddDocusignTemplates} />
        {isSubmitting && (
          <Box my={2}>
            <LinearProgress />
          </Box>
        )}
        <UploadFilesList
          categoriesFor={categoriesFor}
          allowSetPublic={allowSetPublic && isStandalone}
          allowAddToRepository={allowAddToRepository && isSelectMode}
        />
        {isStandalone && (
          <Box pt={2}>
            <TooltipButton
              startIcon={<CloudUploadIcon />}
              disabled={!canSubmit}
              title={canSubmit ? '' : 'Select files to upload'}
              type="submit"
              color="primary"
            >
              {isSubmitting ? 'Uploading...' : 'Upload files'}
            </TooltipButton>
          </Box>
        )}

        {showRepositoryFiles ? (
          <>
            <Box my={2}>
              <Typography variant="h6">{isSelectMode ? 'Choose From Library' : 'Files Library'}</Typography>
            </Box>
            <FilesList
              showDateAdded
              categoriesFor={categoriesFor}
              selectedIds={selectedIds}
              onSelectFile={handleFileSelection}
              selectable={isSelectMode}
              editable={isStandalone}
              queryCondition={repositoryFilesQuery}
              getFileBadges={isStandalone ? showFileBadges : undefined}
              getFiles={getFiles}
            />
          </>
        ) : null}
      </Form>
      <ErrorSnackbar {...uploadErrorSnackbar}>
        Failed to upload files
      </ErrorSnackbar>
    </FormikContext.Provider>
  );
});
