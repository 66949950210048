import React from 'react';
import {
  Box, Typography, Alert, AlertProps,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { colors } from '../theme';
import { SupportButton } from './SupportButton';
import { useErrorDetails } from './ErrorSnackbar';

const useStyles = makeStyles((theme) => createStyles({
  error: {
    color: colors.red,
  },
  fullScreen: {
    width: '100vw',
    height: '100vh',
  },
  inline: {
    display: 'inline-block',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mb: (props: { mb: number | undefined }) => ({
    marginBottom: props.mb ? theme.spacing(props.mb) : undefined,
  }),
}));

export interface ErrorMessageProps {
  fullScreen?: boolean;
  /**
   * Show as an inline block.
   * `center` will override this, use it when you also use `alert`
   */
  inline?: boolean;
  center?: boolean;
  /** Bottom margin */
  mb?: number;
  /**
   * A technical error message not meant to be shown to users.
   * This will only be visible in local and DEV environments.
   * In admin-only UI, you can still pass this message as a child instead,
   * since it is okay if admins see technical details in messages.
   */
  devMessage?: string | Error;
  alert?: boolean;
  alertProps?: AlertProps;
  /** Shows a button to contact tech support via FreshChat widget */
  contactTechSupport?: boolean;

  children?: React.ReactNode;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  fullScreen,
  inline,
  center,
  mb,
  devMessage,
  alert,
  alertProps,
  contactTechSupport,
  children,
}) => {
  const classes = useStyles({ mb });

  const {
    showMessage,
    devMessage: devErrorMessage,
    isUserFriendly,
    childrenNoTrailingPunctuation,
  } = useErrorDetails({ devError: devMessage, children });

  const contactSupportButton = (
    <Box mt={1}>
      <SupportButton
        replyText={`Hello,\n\n\n\n=== TECHNICAL ERROR INFO ===\nLocation: ${
          window.location
        }\nError: ${devMessage?.toString()}`}
      >
        Contact Tech Support
      </SupportButton>
    </Box>
  );

  const dev = (showMessage && devErrorMessage) ? (
    <>
      {!isUserFriendly ? (
        <>
          <b>Dev details:</b>
          <br />
        </>
      ) : ': '}
      {devErrorMessage}
    </>
  ) : '';

  if (alert) {
    return (
      <Box
        display={inline ? 'inline-block' : undefined}
        className={
          [
            center ? classes.center : '',
            fullScreen ? classes.fullScreen : '',
            classes.mb,
          ].join(' ')
        }
      >
        <Alert severity="error" variant="filled" {...alertProps}>
          {childrenNoTrailingPunctuation}
          {dev}
          {contactTechSupport && (
            contactSupportButton
          )}
        </Alert>
      </Box>
    );
  }

  return (
    <Typography
      component="div"
      variant="body1"
      className={
        [
          classes.error,
          fullScreen ? classes.fullScreen : '',
          inline ? classes.inline : '',
          center ? classes.center : '',
          classes.mb,
        ].join(' ')
      }
    >
      {childrenNoTrailingPunctuation}
      {dev}
      {contactTechSupport && (
        contactSupportButton
      )}
    </Typography>
  );
};
