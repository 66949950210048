import React, { useEffect, useMemo } from 'react';
import {
  Box, Link, Typography, Alert, AlertTitle,
} from '@mui/material';
import { useQuery } from '@apollo/react-hooks';
import { createStyles, makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PATHS } from '../paths';
import { useProfile } from '../api/user/profile';
import { Profile_profile_organization } from '../api/types/Profile';
import { ErrorSnackbar, useErrorSnackbar } from '../components/notifications/ErrorSnackbar';
import { BidStatus } from '../api/bids/bidStatuses';
import { BundleStatus } from '../api/bundles/bundleStatuses';
import { Permissions } from '../api/permissions/permissions';
import { SP_BIDS_COUNT } from './serviceProviderQueries/spBidsCount';
import { SpBidsCount, SpBidsCountVariables } from '../api/types/SpBidsCount';
import { StyledRouterLink } from '../components/StyledRouterLink';

export const isSubscribed = (organization: Profile_profile_organization) => (
  !!organization.subscription
);

const useStyles = makeStyles(() => createStyles({
  link: {
    cursor: 'pointer',
  },
}));

/**
 * Normal link doesn't work if we're on Organization page already -
 * it will not switch the tab to "Billing", so we have to use history API explicitly.
 *
 * @param path
 * @param children
 * @constructor
 */
const LinkConstructor: React.FC<{
  path: string;
  children?: React.ReactNode;
}> = ({ path, children }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Link
      className={classes.link}
      onClick={() => history.push(path)}
      color="inherit"
    >
      <strong>{children}</strong>
    </Link>
  );
};

/**
 * We show this text in Alert blocks if SP not subscribed, but have allowance for some bids
 * @param organization
 * @constructor
 */
const SBAMessage: React.FC<{ sbaCount: number }> = ({
  sbaCount,
}) => (sbaCount > 0 ? (
  <b>
    <br />
    In certain cases, you may be able to submit RFPs without subscribing.
    Please look for a
    {' '}
    <FontAwesomeIcon icon="gift" size="lg" />
    {' '}
    icon in your
    {' '}
    <LinkConstructor path={PATHS.sp.bundles}>
      Bid Center
    </LinkConstructor>
    .
  </b>
) : null);

export interface ServiceProviderInnerPageProps {
  setInvitesCount: (count: number) => void;
  children?: React.ReactNode;
}

/**
 * This component exists because we need to use `useProfile` hook,
 * which is only available inside `InnerPage` component.
 * Otherwise, this logic could be moved into ServiceProviderPage
 * @see {ServiceProviderPage}
 */
export const ServiceProviderInnerPage: React.FC<ServiceProviderInnerPageProps> = ({
  setInvitesCount,
  children,
}) => {
  const profile = useProfile();
  const { organization } = profile;
  const isOrgOwner = useMemo(
    () => !!profile.userPermissions.find(
      (p) => p.permission_id === Permissions.ORGANIZATION_OWNER,
    ),
    [profile.userPermissions],
  );

  const {
    data: countData,
    error: countError,
  } = useQuery<SpBidsCount, SpBidsCountVariables>(SP_BIDS_COUNT, {
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        spStatus: {
          status_id: { in: [BidStatus.Invited, BidStatus.Reopened, BidStatus.Negotiating] },
        },
        bundleStatus: {
          status_id: {
            in: [BundleStatus.Negotiating, BundleStatus.OutForBid, BundleStatus.Paused],
          },
        },
      },
    },
  });
  const countErrorSnackbar = useErrorSnackbar(countError);

  useEffect(() => {
    if (countData) {
      setInvitesCount(countData.spBids.total_count);
    }
  }, [countData, setInvitesCount]);

  const alert = useMemo(() => {
    if (organization.sp_bid_access < 0) {
      return (
        <Box mb={2}>
          <Alert severity="warning" variant="filled">
            <Typography variant="body1">
              Sorry, your account is suspended from submitting RFPs
              {' '}
              as a result of past due invoices.
              {' '}
              You can review invoices
              {' '}
              <StyledRouterLink to={PATHS.sp.organizationInfo.invoices}>here</StyledRouterLink>
              {'. '}
              Please contact Customer Support at 617-766-0846 or via Chat Messaging
            </Typography>
          </Alert>
        </Box>
      );
    }
    // PROC-3857 - temporary removed "not subscribed" banner
    // eslint-disable-next-line no-constant-condition
    if (!isSubscribed(organization) && false) {
      return (
        <Box mb={2}>
          <Alert severity="warning" variant="filled">
            <AlertTitle>
              You are not subscribed.
            </AlertTitle>
            <Typography variant="body1">
              {isOrgOwner ? (
                <>
                  Please complete your profile for all your services and
                  {' '}
                  <LinkConstructor path={PATHS.sp.organizationInfo.billing}>
                    subscribe
                  </LinkConstructor>
                  {' '}
                  to respond to bid requests.
                </>
              ) : (
                <>
                  Please contact the organization owner to subscribe
                  to Procursys and be able to submit bids.
                </>
              )}
              <SBAMessage sbaCount={1} />
            </Typography>
          </Alert>
        </Box>
      );
    }
    return null;
  }, [organization, isOrgOwner]);

  return (
    <>
      {alert}
      {children}
      <ErrorSnackbar {...countErrorSnackbar}>
        Failed to get a number of invites for bidding
      </ErrorSnackbar>
    </>
  );
};
