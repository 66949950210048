import React, { Suspense } from 'react';
import { useTheme } from '@mui/styles';
import {
  Box,
  useMediaQuery,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { ErrorPageMessage } from 'components/notifications/ErrorPageMessage';
import { SmartDrawer } from 'components/nav/SmartDrawer';
import { MenuListItem, MenuListItemOrDivider } from 'components/nav/menu';
import { ProfileContext } from 'api/user/profile';
import { ErrorMessage } from 'components/notifications/ErrorMessage';
import { Loading } from 'components/Loading';
import { NavType } from 'components/nav/navType';
import { DialogProvider } from 'components/Dialog';
import useMapsAPI from 'components/map/utils/useMapsAPI';
import { useProfileWithAuth } from './useProfileWithAuth';
import { ChatSidebarProvider } from '../components/chat/ChatSidebar/ChatSidebarProvider';
import { ChatProvider } from '../components/chat/ChatProvider';

export const NAVIGATION_MENU_WIDTH = 36;

export interface InnerPageProps {
  navType: NavType;
  primaryNav: MenuListItem[];
  settingsNav?: MenuListItem[]; // Leave empty for standard menu: user profile menu, settings, help
  children?: React.ReactNode;
}

export const BlankInnerPage: React.FC<InnerPageProps> = ({
  navType,
  primaryNav,
  settingsNav,
  children,
}) => {
  const theme = useTheme();
  const { mapsApiLoaded } = useMapsAPI();
  const drawerWidthOpen = parseInt(theme.spacing(NAVIGATION_MENU_WIDTH), 10);
  const upMd = useMediaQuery(theme.breakpoints.up('md'));
  const isDesktop = upMd;
  const isMobile = !upMd;

  const history = useHistory();

  const {
    profileLoading,
    profileData,
    profileError,
    firebaseAuth,
  } = useProfileWithAuth(history);

  const settingsMenu: MenuListItem[] = settingsNav || [
    // {
    //   id: 'user-account',
    //   type: '_menu_item_onclick',
    //   icon: firebaseAuth.user && firebaseAuth.user.photoURL ? (
    //     <Avatar alt="user menu" src={firebaseAuth.user.photoURL} />
    //   ) : (
    //     <SvgIcon>
    //       <FontAwesomeIcon icon="user-cog" />
    //     </SvgIcon>
    //   ),
    //   text: 'Your Account',
    //   onClick: (e) => setProfileMenuAnchor(e.currentTarget),
    // },
  ];

  if (
    profileLoading
    || firebaseAuth.loggedIn === undefined
    || !mapsApiLoaded
  ) {
    return <Loading center fullScreen />;
  }

  if (profileError) {
    return (
      <ErrorMessage
        fullScreen
        center
        devMessage={profileError?.message}
      >
        Failed loading profile data
      </ErrorMessage>
    );
  }

  if (!profileData) {
    return (
      <ErrorMessage fullScreen center devMessage="data object is not set">
        Failed loading profile data
      </ErrorMessage>
    );
  }

  const { profile } = profileData;
  const { organization } = profile;

  if (!organization) {
    return (
      <ErrorMessage fullScreen center devMessage="Organization is not set in profile data">
        No organization set for the current user
      </ErrorMessage>
    );
  }

  const navCombined: MenuListItemOrDivider[] = [
    ...(isDesktop ? [...primaryNav] as MenuListItemOrDivider[] : []),
    ...(isMobile ? ['divider', ...settingsMenu] as MenuListItemOrDivider[] : []),
  ];

  const isSP = organization.is_service_provider;
  const isFM = organization.is_facility_manager;
  const isAdmin = profile.is_system_admin;

  if (
    (navType === 'sp' && !isSP)
    || (navType === 'fm' && !isFM)
    || (navType === 'admin' && !isAdmin)
  ) {
    return <ErrorPageMessage errorCode="403" message="Forbidden" />;
  }

  return (
    <ProfileContext.Provider value={profileData}>
      <ChatProvider>
        <SmartDrawer
          navType={navType}
          widthOpen={drawerWidthOpen}
          appBar={() => <span />}
          menu={navCombined}
          mobileMenu={primaryNav}
          desktop={upMd}
          showProcursysLogo
          newStyle
          disableHorizontalScroll
        >
          <DialogProvider>
            <ChatSidebarProvider>
              <Box padding={2} paddingBottom={isMobile ? 12 : undefined}>
                <Suspense fallback={<Loading center />}>
                  {children}
                </Suspense>
              </Box>
            </ChatSidebarProvider>
          </DialogProvider>
        </SmartDrawer>
      </ChatProvider>
    </ProfileContext.Provider>
  );
};
