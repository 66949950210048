import React, {
  useEffect, useMemo, useState,
} from 'react';
import type { Event } from 'stream-chat';
import clsx from 'clsx';
import { useDropzone } from 'react-dropzone';
import { IconButton, Tooltip } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import {
  AttachmentPreviewList as DefaultAttachmentPreviewList,
  CooldownTimer as DefaultCooldownTimer,
  SendButton as DefaultSendButton,
  QuotedMessagePreview as DefaultQuotedMessagePreview,
  QuotedMessagePreviewHeader, LinkPreviewList as DefaultLinkPreviewList,
  ChatAutoComplete,
  useChatContext, useChannelActionContext, useChannelStateContext,
  useTranslationContext, useMessageInputContext, useComponentContext,
} from 'stream-chat-react';
import type { DefaultStreamChatGenerics as DSCG } from 'stream-chat-react';

import { DocumentRepositoryDialog } from './DocumentRepositoryDialog';
import { useChatContext as useProcChatContext } from '../ChatContext';

export const MessageInput = <StreamChatGenerics extends DSCG = DSCG>() => {
  const { t } = useTranslationContext('MessageInputFlat');
  const { additionalProps: { defaultMessageValue } = {} } = useProcChatContext();
  const {
    attachments,
    cooldownRemaining,
    findAndEnqueueURLsToEnrich,
    handleSubmit,
    hideSendButton,
    isUploadEnabled,
    linkPreviews,
    maxFilesLeft,
    message,
    numberOfUploads,
    parent,
    setCooldownRemaining,
    text,
    uploadNewFiles,
    insertText,
  } = useMessageInputContext<StreamChatGenerics>('MessageInputFlat');

  const {
    AttachmentPreviewList = DefaultAttachmentPreviewList,
    CooldownTimer = DefaultCooldownTimer,
    LinkPreviewList = DefaultLinkPreviewList,
    QuotedMessagePreview = DefaultQuotedMessagePreview,
    SendButton = DefaultSendButton,
    EmojiPicker,
  } = useComponentContext<StreamChatGenerics>('MessageInputFlat');
  const {
    acceptedFiles = [],
    multipleUploads,
    quotedMessage,
  } = useChannelStateContext<StreamChatGenerics>('MessageInputFlat');
  const { setQuotedMessage } = useChannelActionContext('MessageInputFlat');
  const { channel } = useChatContext<StreamChatGenerics>('MessageInputFlat');

  const [filesDialogVisible, setFilesDialogVisible] = useState<boolean>(false);

  const failedUploadsCount = useMemo(
    () => attachments.filter((a) => a.localMetadata?.uploadState === 'failed').length,
    [attachments],
  );

  const accept = useMemo(
    () => acceptedFiles.reduce<Record<string, Array<string>>>((mediaTypeMap, mediaType) => {
      // eslint-disable-next-line no-param-reassign
      mediaTypeMap[mediaType] ??= [];
      return mediaTypeMap;
    }, {}),
    [acceptedFiles],
  );

  const { getRootProps, isDragActive, isDragReject } = useDropzone({
    accept: Object.keys(accept),
    disabled: !isUploadEnabled || maxFilesLeft === 0,
    multiple: multipleUploads,
    noClick: true,
    onDrop: uploadNewFiles,
  });

  useEffect(() => {
    const handleQuotedMessageUpdate = (e: Event<StreamChatGenerics>) => {
      if (e.message?.id !== quotedMessage?.id) return;
      if (e.type === 'message.deleted') {
        setQuotedMessage(undefined);
        return;
      }
      setQuotedMessage(e.message);
    };
    channel?.on('message.deleted', handleQuotedMessageUpdate);
    channel?.on('message.updated', handleQuotedMessageUpdate);

    return () => {
      channel?.off('message.deleted', handleQuotedMessageUpdate);
      channel?.off('message.updated', handleQuotedMessageUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, quotedMessage]);

  // TODO: "!message" condition is a temporary fix for shared
  // state when editing a message (fix shared state issue)
  const displayQuotedMessage = !message && quotedMessage && quotedMessage.parent_id === parent?.id;
  const showAttachmentPreview = isUploadEnabled
    && !!(numberOfUploads + failedUploadsCount || attachments.length > 0);

  useEffect(() => {
    if (defaultMessageValue) {
      insertText(defaultMessageValue);
    }
  }, [insertText, defaultMessageValue]);

  return (
    <div {...getRootProps({ className: 'str-chat__message-input' })}>
      {findAndEnqueueURLsToEnrich && (
        <LinkPreviewList linkPreviews={Array.from(linkPreviews.values())} />
      )}
      {isDragActive && (
        <div
          className={clsx('str-chat__dropzone-container', {
            'str-chat__dropzone-container--not-accepted': isDragReject,
          })}
        >
          {!isDragReject && <p>{t<string>('Drag your files here')}</p>}
          {isDragReject && <p>{t<string>('Some of the files will not be accepted')}</p>}
        </div>
      )}
      {displayQuotedMessage && <QuotedMessagePreviewHeader />}

      <div className="str-chat__message-input-inner">
        {isUploadEnabled && !cooldownRemaining && (
          <div className="str-chat__fileupload-wrapper" data-testid="fileinput">
            <Tooltip title={maxFilesLeft ? t<string>('Attach files') : t<string>("You've reached the maximum number of files")}>
              <IconButton
                onClick={() => setFilesDialogVisible(true)}
                aria-label="Attach file"
                size="medium"
              >
                <AttachFileIcon />
              </IconButton>
            </Tooltip>
            <DocumentRepositoryDialog
              onSelected={async (files) => {
                uploadNewFiles(files);
                setFilesDialogVisible(false);
              }}
              open={filesDialogVisible}
              onClose={() => setFilesDialogVisible(false)}
            />
          </div>
        )}
        <div className="str-chat__message-textarea-container">
          {displayQuotedMessage && <QuotedMessagePreview quotedMessage={quotedMessage} />}
          {showAttachmentPreview && (<AttachmentPreviewList />)}
          <div className="str-chat__message-textarea-with-emoji-picker">
            <ChatAutoComplete />
            {EmojiPicker && <EmojiPicker />}
          </div>
        </div>
        {(
          !hideSendButton && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {cooldownRemaining ? (
                <CooldownTimer
                  cooldownInterval={cooldownRemaining}
                  setCooldownRemaining={setCooldownRemaining}
                />
              ) : (
                <SendButton
                  disabled={
                    !numberOfUploads
                    && !text.length
                    && attachments.length - failedUploadsCount === 0
                  }
                  sendMessage={handleSubmit}
                />
              )}
            </>
          )
        )}
      </div>
    </div>
  );
};
