import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Paper } from '@mui/material';
import { ErrorMessage } from '../components/notifications/ErrorMessage';
import { AdminPage } from './AdminPage';
import { ServiceProviderPage } from './ServiceProviderPage';
import { FacilityManagerPage } from './FacilityManagerPage';
import { AuthPage } from './AuthPage';

export const NotFoundPage = () => {
  const { location } = useHistory();
  const notFoundBody = (
    LayoutComponent: React.FC<{ children?: React.ReactNode }>,
    paper: boolean,
  ) => {
    const msg = (
      <Box m={2}>
        <ErrorMessage center>
          <b>
            404
          </b>
          : Not found
        </ErrorMessage>
      </Box>
    );
    return (
      <LayoutComponent>
        {
          paper ? (
            <Box m={2}>
              <Paper>
                {msg}
              </Paper>
            </Box>
          ) : msg
        }
      </LayoutComponent>
    );
  };
  // const navType = location.
  if (location.pathname.startsWith('/admin')) {
    return notFoundBody(AdminPage, true);
  }
  if (location.pathname.startsWith('/sp')) {
    return notFoundBody(ServiceProviderPage, true);
  }
  if (location.pathname.startsWith('/fm')) {
    return notFoundBody(FacilityManagerPage, true);
  }
  return notFoundBody(AuthPage, false);
};

export default NotFoundPage;
