import React, { useState } from 'react';
import {
  Grid, Box,
  Typography,
  Tabs, Tab, SvgIcon,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { useProfile } from 'api/user/profile';
import { Permissions } from 'api/permissions/permissions';
import { UsersTable } from './members/UsersTable';
import { NavTypeNoAdmin } from '../../nav/navType';
import { useStyles } from './styles';
import { a11yProps, TabPanel } from '../../tabs/TabPanel';
import { InvitesPage } from './invites/InvitesPage';
import {
  OnBoardingKeyContacts,
} from '../sp/onboarding/pages/sub/OnBoardingKeyContacts/OnBoardingKeyContacts';
import { SingleBoxLayout } from '../../layout/SingleBoxLayout';

export interface OrganizationTeamProps { navType: NavTypeNoAdmin }

export const OrganizationTeam: React.FC<OrganizationTeamProps> = ({ navType }) => {
  const classes = useStyles();
  const history = useHistory();
  const profile = useProfile();

  const search = new URLSearchParams(history.location.search);
  const [currentValue, setCurrentValue] = useState<string>(
    () => search.get('s') ?? 'members',
  );

  const canInviteUsers = !!profile.userPermissions.find(
    ({ permission_id }) => permission_id === Permissions.INVITE_USERS,
  );

  const tabs = [
    {
      label: 'Members',
      value: 'members',
      icon: 'users' as IconProp,
      body: (
        <Grid container direction="column" className={classes.tablesWrapper}>
          {canInviteUsers && (
            <>
              <Grid item className={classes.sectionTitle} xs={12}>
                <Typography variant="h5" gutterBottom>Invitations</Typography>
              </Grid>
              <Grid item xs={12}>
                <InvitesPage />
              </Grid>
            </>
          )}
          <Grid item className={classes.sectionTitle} xs={12}>
            <Typography variant="h5" gutterBottom>Members</Typography>
          </Grid>
          <Grid item xs={12}>
            <UsersTable navType={navType} />
          </Grid>
        </Grid>
      ),
    },
    {
      label: 'Key Contacts',
      value: 'key_contacts',
      icon: 'address-card' as IconProp,
      body: <OnBoardingKeyContacts mode="standalone" />,
    },
  ];

  return (
    <SingleBoxLayout title={navType === 'sp' ? 'Team' : 'Organization'}>
      <Box>
        <Tabs value={currentValue}>
          {tabs.map(({ label, value, icon }) => (
            <Tab
              label={label}
              icon={typeof icon === 'string' ? (
                <SvgIcon>
                  <FontAwesomeIcon icon={icon} />
                </SvgIcon>
              ) : undefined}
              {...a11yProps(value)}
              onClick={() => {
                history.push({
                  ...history.location,
                  search: new URLSearchParams({ s: value }).toString(),
                });
                setCurrentValue(value);
              }}
            />
          ))}
        </Tabs>
        <Box m={2}>
          {tabs.map(({ value, body }) => (
            <TabPanel index={value} value={currentValue}>
              {body}
            </TabPanel>
          ))}
        </Box>
      </Box>
    </SingleBoxLayout>
  );
};

export default OrganizationTeam;
