import {
  useChatContext as useDefaultChatContext,
  useChannelStateContext as useDefaultChannelStateContext,
} from 'stream-chat-react';
import { ChatGenerics } from '../streamTypes';

export const useChannelStateContext = (componentName?: string) => useDefaultChannelStateContext<
  ChatGenerics
>(componentName);

export const useChatContext = (componentName?: string) => useDefaultChatContext<
  ChatGenerics
  >(componentName);
