import React, { useMemo, useRef } from 'react';
import clsx from 'clsx';
import {
  ChannelPreviewUIComponentProps,
  Avatar as DefaultAvatar,
} from 'stream-chat-react';
import { CloseOutlined, UnarchiveOutlined } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';
import { ChatGenerics } from '../streamTypes';
import { useProfile } from '../../../api/user/profile';
import { getChannelArchivedKey, getChannelName } from '../utils';
import { TooltipIconButton } from '../../buttons/TooltipIconButton';
import { colors } from '../../theme';
import { Permissions } from '../../../api/permissions/permissions';

const useStyles = makeStyles(() => createStyles({
  roomItem: {
    minHeight: 70,
    '&:hover': {
      '& $iconWrapper': {
        display: 'flex',
      },
    },
  },
  actions: {
    display: 'flex',
  },
  iconWrapper: {
    display: 'none',
    justifyContent: 'flex-end',
    flexGrow: 2,
  },
  archivedTitle: {
    textDecoration: 'line-through',
    color: colors.grey,
  },
  unreadCount: {
    display: 'flex',
  },
  unreadTitle: {
    fontWeight: 700,
  },
  badge: {
    width: 15,
    height: 15,
    fontSize: 16,
    backgroundColor: colors.orange,
    color: colors.white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    marginRight: 10,
  },
}));

/**
 * Component responsive for item in channels list
 * Mostly a full copy of https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelPreview/ChannelPreviewMessenger.tsx
 * with some custom stuff like archiving etc
 */

export const ChannelPreviewMessenger: React.FC<ChannelPreviewUIComponentProps<ChatGenerics>> = ({
  active,
  Avatar = DefaultAvatar,
  channel,
  displayImage,
  latestMessagePreview,
  setActiveChannel,
  unread,
  watchers,
  className: customClassName = '',
  groupChannelDisplayInfo,
}) => {
  const classes = useStyles();

  const profile = useProfile();

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);
  const isOrgOwner = useMemo(
    () => !!profile.userPermissions.find(
      (p) => p.permission_id === Permissions.ORGANIZATION_OWNER,
    ),
    [profile.userPermissions],
  );

  const onSelectChannel = () => {
    if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  const archivedByChannelKey = useMemo(() => getChannelArchivedKey(profile), [profile]);
  const isArchived = useMemo(() => !!channel.data?.[archivedByChannelKey],
    [archivedByChannelKey, channel.data],
  );

  const archiveRestoreChannel = async () => {
    await channel.updatePartial({
      set: { [archivedByChannelKey]: !isArchived },
    });
  };

  const displayTitle = getChannelName(channel, profile);
  const avatarName = displayTitle
    || channel.state.messages[channel.state.messages.length - 1]?.user?.id;

  return (
    <div className="str-chat__channel-preview-container">
      {/* <ChannelPreviewActionButtons channel={channel} />*/}
      <button
        aria-label={`Select Channel: ${displayTitle || ''}`}
        aria-selected={active}
        className={clsx(
          'str-chat__channel-preview-messenger str-chat__channel-preview',
          active && 'str-chat__channel-preview-messenger--active',
          unread && unread >= 1 && 'str-chat__channel-preview-messenger--unread',
          customClassName,
          classes.roomItem,
        )}
        data-testid="channel-preview-button"
        onClick={onSelectChannel}
        ref={channelPreviewButton}
        role="option"
        type="button"
      >
        <div className="str-chat__channel-preview-messenger--left">
          <Avatar
            className="str-chat__avatar--channel-preview"
            groupChannelDisplayInfo={groupChannelDisplayInfo}
            image={displayImage}
            name={avatarName}
          />
        </div>
        <div className="str-chat__channel-preview-end">
          <div className="str-chat__channel-preview-end-first-row">
            <div
              className={`str-chat__channel-preview-messenger--name ${
                isArchived ? classes.archivedTitle : ''
              } ${unread !== undefined && unread > 0 ? classes.unreadTitle : ''}`}
            >
              <span>{displayTitle}</span>
            </div>
          </div>
          <div className="str-chat__channel-preview-messenger--last-message">
            {isArchived ? '' : latestMessagePreview}
          </div>
        </div>
        <div className={classes.actions}>
          {unread !== undefined && unread > 0 && (
            <div className={`${classes.iconWrapper} ${classes.unreadCount}`}>
              <Tooltip title="This room has unread messages">
                <div className={classes.badge} />
              </Tooltip>
            </div>
          )}
          {isOrgOwner && !unread && (
            <div className={classes.iconWrapper}>
              {isArchived
                ? (
                  <TooltipIconButton
                    onClick={archiveRestoreChannel}
                    title="Restore this room"
                    color="primary"
                  >
                    <UnarchiveOutlined />
                  </TooltipIconButton>
                )
                : (
                  <TooltipIconButton
                    onClick={archiveRestoreChannel}
                    title="Archive this room (Only for your organization)"
                    color="primary"
                  >
                    <CloseOutlined />
                  </TooltipIconButton>
                )}
            </div>
          )}
        </div>
      </button>
    </div>
  );
};
