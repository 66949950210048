import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import {
  Attachment as DefaultAttachment,
  Avatar as DefaultAvatar,
  MessageDeleted as DefaultMessageDeleted, messageHasAttachments, messageHasReactions,
  MessageOptions as DefaultMessageOptions,
  MessageRepliesCountButton as DefaultMessageRepliesCountButton,
  MessageStatus as DefaultMessageStatus, MessageText,
  MessageTimestamp as DefaultMessageTimestamp,
  MessageUIComponentProps, MML, Poll,
  useMessageContext,
  EditMessageForm as DefaultEditMessageForm,
  ReactionsList as DefaultReactionList, useComponentContext,
  MessageInput, Modal, useChatContext, useTranslationContext,
  MessageErrorIcon,
  areMessageUIPropsEqual, isMessageBounced, isMessageEdited,
  MessageContextValue,
} from 'stream-chat-react';
import { Channel } from 'stream-chat';

import { useChatContext as useProcursysChatContext } from '../ChatContext';
import { useProfile } from '../../../api/user/profile';
import { ChatGenerics, MessageSentFrom } from '../streamTypes';
import { getMessageSenderName } from '../utils';

const {
  MessageEditedTimestamp,
  MessageBounceModal,
  MessageBouncePrompt: DefaultMessageBouncePrompt,
} = require('stream-chat-react');

export const CUSTOM_MESSAGE_TYPE = {
  date: 'message.date',
  intro: 'channel.intro',
};

const sentFromTitles: Record<MessageSentFrom, string> = {
  [MessageSentFrom.Web]: 'web',
  [MessageSentFrom.App]: 'mobile app',
  [MessageSentFrom.Phone]: 'phone',
};

type MessageSimpleWithContextProps<StreamChatGenerics extends ChatGenerics = ChatGenerics>
  = MessageContextValue<StreamChatGenerics>;

const MessageSimpleWithContext = <StreamChatGenerics extends ChatGenerics = ChatGenerics>(
  props: MessageSimpleWithContextProps<StreamChatGenerics>,
) => {
  const {
    additionalMessageInputProps,
    clearEditingState,
    editing,
    endOfGroup,
    firstOfGroup,
    groupedByUser,
    handleAction,
    handleOpenThread,
    handleRetry,
    highlighted,
    isMessageAIGenerated,
    isMyMessage: getIsMyMessage,
    message,
    onUserClick,
    onUserHover,
    renderText,
    threadList,
    message: { user },
  } = props;
  const { client, channel } = useChatContext<StreamChatGenerics>('MessageSimple');
  const { t } = useTranslationContext('MessageSimple');
  const { botMessagesHidden } = useProcursysChatContext();
  const [isBounceDialogOpen, setIsBounceDialogOpen] = useState(false);
  const [isEditedTimestampOpen, setEditedTimestampOpen] = useState(false);

  const {
    Attachment = DefaultAttachment,
    Avatar = DefaultAvatar,
    EditMessageInput = DefaultEditMessageForm,
    MessageOptions = DefaultMessageOptions,
    MessageActions = MessageOptions,
    MessageDeleted = DefaultMessageDeleted,
    MessageBouncePrompt = DefaultMessageBouncePrompt,
    MessageRepliesCountButton = DefaultMessageRepliesCountButton,
    MessageStatus = DefaultMessageStatus,
    MessageTimestamp = DefaultMessageTimestamp,
    ReactionsList = DefaultReactionList,
    PinIndicator,
  } = useComponentContext<StreamChatGenerics>('MessageSimple');

  const hasAttachment = messageHasAttachments(message);
  const hasReactions = messageHasReactions(message);
  const isAIGenerated = useMemo(() => isMessageAIGenerated?.(message), [
    isMessageAIGenerated,
    message,
  ]);

  const profile = useProfile();
  const { organization: { organization_id: myOrgId } } = profile;

  const isMyMessage = getIsMyMessage();
  const isCoworkerMessage = (user?.organization_id === myOrgId && !isMyMessage)
    || (message.is_notification && message.from_organization_id === myOrgId);

  const userName = useMemo(() => getMessageSenderName(user, channel as Channel<any>, profile),
    [channel, user, profile],
  );

  if (message.customType === CUSTOM_MESSAGE_TYPE.date) {
    return null;
  }

  if (message.deleted_at || message.type === 'deleted') {
    return <MessageDeleted message={message} />;
  }

  if (message.is_notification && botMessagesHidden) {
    return null;
  }

  const showMetadata = !groupedByUser || endOfGroup;
  const showReplyCountButton = !threadList && !!message.reply_count;
  const allowRetry = message.status === 'failed' && message.errorStatusCode !== 403;
  const isBounced = isMessageBounced(message);
  const isEdited = isMessageEdited(message) && !isAIGenerated;

  let handleClick: (() => void) | undefined;

  if (allowRetry) {
    handleClick = () => handleRetry(message);
  } else if (isBounced) {
    handleClick = () => setIsBounceDialogOpen(true);
  } else if (isEdited) {
    handleClick = () => setEditedTimestampOpen((prev) => !prev);
  }

  const rootClassName = clsx(
    'str-chat__message str-chat__message-simple',
    `str-chat__message--${message.type}`,
    `str-chat__message--${message.status}`,
    isMyMessage || isCoworkerMessage
      ? 'str-chat__message--me str-chat__message-simple--me'
      : 'str-chat__message--other',
    message.text ? 'str-chat__message--has-text' : 'has-no-text',
    // messageClasses,
    isCoworkerMessage ? 'str-chat__message--coworker str-chat__message-simple--coworker' : '',
    {
      'str-chat__message--has-attachment': hasAttachment,
      'str-chat__message--highlighted': highlighted,
      'str-chat__message--pinned pinned-message': message.pinned,
      'str-chat__message--with-reactions': hasReactions,
      'str-chat__message-send-can-be-retried':
        message?.status === 'failed' && message?.errorStatusCode !== 403,
      'str-chat__message-with-thread-link': showReplyCountButton,
      'str-chat__virtual-message__wrapper--end': endOfGroup,
      'str-chat__virtual-message__wrapper--first': firstOfGroup,
      'str-chat__virtual-message__wrapper--group': groupedByUser,
    },
  );

  const poll = message.poll_id && client.polls.fromState(message.poll_id);

  return (
    <>
      {editing && (
        <Modal className="str-chat__edit-message-modal" onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={clearEditingState}
            grow
            hideSendButton
            Input={EditMessageInput}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      {isBounceDialogOpen && (
        <MessageBounceModal
          MessageBouncePrompt={MessageBouncePrompt}
          onClose={() => setIsBounceDialogOpen(false)}
          open={isBounceDialogOpen}
        />
      )}
      <div className={rootClassName} key={message.id}>
        {PinIndicator && <PinIndicator />}
        {message.user && (
          <Avatar
            image={message.user.image}
            name={message.user.name || message.user.id}
            onClick={onUserClick}
            onMouseOver={onUserHover}
            user={message.user}
          />
        )}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          className={clsx('str-chat__message-inner', {
            'str-chat__simple-message--error-failed': allowRetry || isBounced,
          })}
          data-testid="message-inner"
          onClick={handleClick}
          onKeyUp={handleClick}
        >
          <MessageActions />
          <div className="str-chat__message-reactions-host">
            {hasReactions && <ReactionsList reverse />}
          </div>
          <div className="str-chat__message-bubble">
            {poll && <Poll poll={poll} />}
            {message.attachments?.length && !message.quoted_message ? (
              <Attachment actionHandler={handleAction} attachments={message.attachments} />
            ) : null}
            <MessageText message={message} renderText={renderText} />
            {message.mml && (
              <MML
                actionHandler={handleAction}
                align={isMyMessage ? 'right' : 'left'}
                source={message.mml}
              />
            )}
            <MessageErrorIcon />
          </div>
        </div>
        {showReplyCountButton && (
          <MessageRepliesCountButton
            onClick={handleOpenThread}
            reply_count={message.reply_count}
          />
        )}
        {showMetadata ? (
          <div className="str-chat__message-metadata">
            <MessageStatus />
            <span className="str-chat__message-simple-name">
              {!isMyMessage ? userName : null}
            </span>
            {message.is_notification && (
              <span style={{ verticalAlign: 'baseline' }}>
                {!isMyMessage ? ' | ' : ' '}
                <strong style={{ textTransform: 'uppercase' }}>Auto message</strong>
                {' | '}
              </span>
            )}
            {message.sent_from && (
              <>
                sent from
                {' '}
                <strong>{sentFromTitles[message.sent_from as MessageSentFrom]}</strong>
                {' | '}
              </>
            )}
            <MessageTimestamp customClass="str-chat__message-simple-timestamp" />
            {isEdited && (
              <span className="str-chat__mesage-simple-edited">{t<string>('Edited')}</span>
            )}
            {isEdited && <MessageEditedTimestamp calendar open={isEditedTimestampOpen} />}
          </div>
        ) : null}
      </div>
    </>
  );
};

const MemoizedMessageSimple = React.memo(
  MessageSimpleWithContext,
  areMessageUIPropsEqual,
) as typeof MessageSimpleWithContext;

/**
 * The default UI component that renders a message and receives
 * functionality and logic from the MessageContext.
 */
export const Message = <
  StreamChatGenerics extends ChatGenerics = ChatGenerics>(
  props: MessageUIComponentProps<StreamChatGenerics>,
) => {
  const messageContext = useMessageContext<StreamChatGenerics>('MessageSimple');
  return <MemoizedMessageSimple {...messageContext} {...props} />;
};
