import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import React, { PropsWithChildren, useRef, useState } from 'react';

export type ActionsMenuItems<DataType> = Array<ActionsMenuItemProps<DataType> | 'divider'>;

export interface ActionsMenuItemProps<DataType> {
  title: string;
  onClick: (data: DataType) => void;
  disabled?: boolean;
}

export interface ActionsMenuProps<DataType> {
  data: DataType;
  items: ActionsMenuItems<DataType> | ((data: DataType) => ActionsMenuItems<DataType>);
}

export const ActionsMenu = <DataType extends unknown>(
  {
    data,
    items,
  }: PropsWithChildren<ActionsMenuProps<DataType>>) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <IconButton
        size="small"
        aria-controls="actions-menu"
        aria-haspopup="true"
        onClick={() => setOpen(true)}
        ref={anchorRef}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        data-testid="actions-menu"
        id="actions-menu"
        anchorEl={anchorRef.current}
        keepMounted
        open={isOpen}
        onClose={() => setOpen(false)}
      >
        {(typeof items === 'function' ? items(data) : items).map((item) => {
          if (item === 'divider') {
            return (
              <MenuItem key="divider" divider />
            );
          }
          const { title, onClick, disabled } = item;
          return (
            <MenuItem
              key={`${title}-menu-item`}
              onClick={() => {
                setOpen(false);
                onClick(data);
              }}
              disabled={disabled}
            >
              {title}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
